<template>
  <mobile-screen
    :header="true"
    screen-class="resources-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="resource-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name:
            actionType && actionType === 'edit'
              ? 'r_resources-help-online-edit-capacity'
              : 'r_resources-help-online-add-capacity'
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "resources",
              "resource-capacity",
              "resource-capacity"
            )
          }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section">
      <li class="clebex-item-section-label-inner">
        <div class="clebex-label-content-wrapper">
          {{ displayLabelName("resources", "resource-capacity", "capacity") }}
        </div>
      </li>
      <li
        class="clebex-item-section-item"
        v-if="actionType && actionType === 'edit'"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="currentCapacity">{{
              displayLabelName("resources", "resource-capacity", "current")
            }}</label>
            <input
              id="currentCapacity"
              type="text"
              name="capacity"
              v-model="currentCapacity"
            />
          </div>
        </div>
      </li>
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="maxCapacity">{{
              displayLabelName("resources", "resource-capacity", "maximum")
            }}</label>
            <input
              type="text"
              name="capacity"
              id="maxCapacity"
              v-model="maxCapacity"
            />
          </div>
        </div>
      </li>
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="defaultCapacity">{{
              displayLabelName("resources", "resource-capacity", "default")
            }}</label>
            <input
              type="text"
              name="capacity"
              id="defaultCapacity"
              v-model="capacity"
            />
          </div>
        </div>
      </li>
    </ul>

    <ul class="clebex-item-section">
      <li class="clebex-item-section-label-inner">
        <div class="clebex-label-content-wrapper">
          {{
            displayLabelName(
              "resources",
              "resource-capacity",
              "type-of-dynamic-capacity"
            )
          }}
        </div>
      </li>
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl">
            <dt class="clebex-item-dt">
              {{ displayLabelName("resources", "resource-capacity", "type") }}
            </dt>
            <dd class="clebex-item-dd">
              <router-link
                v-if="actionType && actionType === 'edit'"
                :to="{
                  name: selectCapacityTypeLinkName,
                  params: $route.params
                }"
              >
                {{
                  selectedResourceCapacityType
                    ? `by ${selectedResourceCapacityType.name}`
                    : displayLabelName(
                        "resources",
                        "resource-capacity",
                        "select-capacity-type"
                      )
                }}
                <icon icon="#cx-app1-arrow-right-12x12" width="12" height="12"
              /></router-link>
              <span v-else>
                {{
                  selectedResourceCapacityType
                    ? `by ${selectedResourceCapacityType.name}`
                    : displayLabelName(
                        "resources",
                        "resource-capacity",
                        "select-capacity-type"
                      )
                }}
              </span>
            </dd>
          </dl>
        </div>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState } from "vuex";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "ResourceCapacity",
  mixins: [helpOnlineMixin],
  data() {
    return {
      helpSlug: "resources-capacity"
    };
  },
  created() {
    if (this.backLinkName.indexOf("r_edit") !== -1) {
      const { resource } = this;

      if (
        resource &&
        resource.data &&
        !this.canItem(this.resource.data, "update")
      ) {
        this.$router.push({ name: "r_resources" });
      }
    }
  },
  computed: {
    ...mapState("resource", ["resource", "selectedResourceCapacityType"]),
    capacity: {
      get() {
        return this.$store.state.resource.capacity;
      },
      set(val) {
        this.$store.commit("resource/setCapacity", val, { root: true });
      }
    },
    maxCapacity: {
      get() {
        return this.$store.state.resource.maxCapacity;
      },
      set(val) {
        this.$store.commit("resource/setMaxCapacity", val, { root: true });
      }
    },
    currentCapacity: {
      get() {
        return this.$store.state.resource.currentCapacity;
      },
      set(val) {
        this.$store.commit("resource/setCurrentCapacity", val, {
          root: true
        });
      }
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    },
    actionType: {
      type: String,
      required: true
    },
    selectCapacityTypeLinkName: {
      type: String,
      required: false
    }
  }
};
</script>
